import React, { useEffect, useRef } from 'react';

const SearchFilter = ({toggle, setToggle, handleSearch, handleClick, handleKeyPress}) => {

  const searchRef = useRef(null);
  const inputRef = useRef(null);

  const expandSearch = () => {
    setToggle(true);
  };

  useEffect(() => {

    const handleOutsideClick = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setToggle(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [setToggle]);

  useEffect(() => {
    if (toggle) {
      inputRef.current.focus();
    }
  }, [toggle]);

    return(
    
        <div ref={searchRef} onClick={expandSearch} className="lg:inline-flex hidden items-center w-auto justify-center ml-3 p-1 transition-all">
            <svg xmlns="http://www.w3.org/2000/svg" 
            className={ toggle ? "hidden" : "absolute h-7 w-7 text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-500 cursor-pointer" } 
            fill="none" 
            viewBox="0 0 24 24"
             stroke="currentColor">
              <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth="2"
               d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" 
            />
            </svg>
            <button onClick={handleClick}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-500 cursor-pointer" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor">
                <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" 
              />
              </svg>
            </button>
          <input
          ref={inputRef} 
          name="search"
          className={toggle ? "w-full flex-grow ml-2 pl-2 appearance-none bg-transparent text-slate-700 dark:text-white ease-in duration-300" : "w-0 appearance-none bg-transparent ease-out duration-0"} 
          type="text" 
          placeholder="Search" 
          onKeyDown={handleKeyPress} 
          onChange={handleSearch} 
          readOnly={!toggle} 
          />
        </div>
    )

}

export default SearchFilter;