import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import Dashboard from './Dashboard';
import AnalysisModal from './AnalysisModal';
import { useLocalStorage } from './useLocalStorage';
import './stylesheets/app.css';

function App() {

  const [country, setCountry] = useState({'name' : 'United States', 'code' : 'us'});
  const [locale, setLocale] = useLocalStorage('locale', {'name' : 'United States', 'code' : 'us'});
  const [lang, setLang] = useLocalStorage('lang', {'name' : 'English', 'code' : 'en'});

  const [isOpen, setIsOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const searchQuery = 'search?q=';
  const headlinesQuery = 'top-headlines?category=';
  const [endpoint, setEndpoint] = useState(headlinesQuery);
  const [input, setInput] =  useLocalStorage('input', 'general');
  const [searchString, setSearchString] = useState(null)
  const [refresh, setRefresh] = useState(false);
  const [layoutPref, setLayoutPref] = useLocalStorage('layout', 'default');
  const [layout, setLayout] = useState(layoutPref);
  const [engageIcon, setEngageIcon] = useState(null);
  const [isTouchDevice, setIsTouchDevice] = useState(('ontouchstart' in document.documentElement && navigator.userAgent.match(/Mobi/)));
  const [analysis, setAnalysis] = useState([]);
  let [isOpenAnalysisModal, setIsOpenAnalysisModal] = useState(false);
  let [isOpenModal, setIsOpenModal] = useState(false);
  const validCategoryInputs = ['general', 'world', 'nation', 'business', 'technology', 'entertainment', 'sports', 'science', 'health'];
  
  const handleCountry = (country) => {
    setLocale(country);
    setEndpoint(headlinesQuery);
    setRefresh(true);
      if(isOpen){
        setIsOpen(false);
      }   
  };

  const handleChange = (country) => {
    setLocale(country);
    setEndpoint(headlinesQuery);
    setRefresh(true);
  };

  const handleLanguage = (language) => {
    if (!endpoint) {
        setEndpoint(headlinesQuery);
    }
    setLang(language);
    setRefresh(true);
  };

  const handleCategory = (e) => {
    const selectedInput = e.target.value;
    if (validCategoryInputs.includes(selectedInput)) {
      setEndpoint(headlinesQuery);
      setInput(selectedInput);
      setRefresh(true);
      if (isOpen) {
        setIsOpen(false);
      }
    }
  };

  const handleSearch = (e) => {
    const inputText = e.target.value.trim();
    if (inputText.length >= 3){
      const inputText = e.target.value.trim();
      const encodedString = encodeURIComponent(e.target.value.toLowerCase().replace(/[^a-z0-9 _-]+/gi, '-'));
      setSearchString(encodedString);
      if (isSearchOpen) {
        setIsSearchOpen(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    const inputText = e.target.value.trim();
    if (inputText.length >= 3 && e.key === 'Enter') {
      const encodedString = encodeURIComponent(inputText.toLowerCase().replace(/[^a-z0-9 _-]+/gi, '-'));
      setSearchString(encodedString);
      setInput(encodedString);
      setEndpoint(searchQuery);
      if (isSearchOpen) {
        setIsSearchOpen(false);
      }
    }
  };

  const handleClick = (e) => {
    setInput(searchString);
    setEndpoint(searchQuery);
  };

  const handleMobileClick = (e) => {
    setIsSearchOpen(prevIsSearchOpen => !prevIsSearchOpen);
    e.stopPropagation();
  };

  return (
    
    <div className="App overflow-x-hidden bg-white dark:bg-slate-950">

      <Header 
      handleCategory={handleCategory} 
      handleChange={handleChange} 
      handleSearch={handleSearch} 
      handleClick={handleClick} 
      handleMobileClick={handleMobileClick} 
      handleLanguage={handleLanguage} 
      handleKeyPress={handleKeyPress} 
      country={country} 
      setCountry={setCountry} 
      handleCountry={handleCountry} 
      isOpen={isOpen} 
      isSearchOpen={isSearchOpen} 
      setIsSearchOpen={setIsSearchOpen}
      setIsOpen={setIsOpen} 
      useLocalStorage={useLocalStorage} 
      locale={locale} 
      engageIcon={engageIcon} 
      setEngageIcon={setEngageIcon} 
      analysis={analysis} 
      setAnalysis={setAnalysis} 
      isOpenAnalysisModal={isOpenAnalysisModal} 
      setIsOpenAnalysisModal={setIsOpenAnalysisModal} 
      toggle={toggle} 
      setToggle={setToggle} 
      endpoint={endpoint} 
      setEndpoint={setEndpoint} 
      setRefresh={setRefresh} 
      input={input} 
      setInput={setInput} 
      headlinesQuery={headlinesQuery} 
      searchQuery={searchQuery} 
      isTouchDevice={isTouchDevice} 
        />

      <Dashboard
      lang={lang} 
      locale={locale} 
      country={country} 
      setCountry={setCountry} 
      handleCountry={handleCountry} 
      isOpenAnalysisModal={isOpenAnalysisModal} 
      setIsOpenAnalysisModal={setIsOpenAnalysisModal} 
      isOpenModal={isOpenModal} 
      setIsOpenModal={setIsOpenModal} 
      endpoint={endpoint} 
      setEndpoint={setEndpoint} 
      refresh={refresh}
      setRefresh={setRefresh} 
      input={input} 
      setInput={setInput} 
      headlinesQuery={headlinesQuery} 
      searchQuery={searchQuery} 
      layout={layout} 
      setLayout={setLayout} 
      layoutPref={layoutPref} 
      setLayoutPref={setLayoutPref} 
      engageIcon={engageIcon} 
      setEngageIcon={engageIcon} 
      analysis={analysis} 
      setAnalysis={setAnalysis} 
      validCategoryInputs={validCategoryInputs} 
      isTouchDevice={isTouchDevice} 
       />

      <AnalysisModal 
      isOpenAnalysisModal={isOpenAnalysisModal} 
      setIsOpenAnalysisModal={setIsOpenAnalysisModal}
      analysis={analysis}
      />

      <Footer />

    </div>
  );
}

export default App;
