import React, {Fragment} from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { LanguageIcon } from '@heroicons/react/20/solid';
import languages from './language-list.json';

const LanguageSelect = ({language, handleLanguage,lang}) => {

    return(

        <div className="flex z-[12] ml-4 md:ml-1">
            <div className="nav-filter-country relative">

        <Listbox value={lang} onChange={handleLanguage}>
            <Listbox.Button className="relative w-full p-1 sm:text-sm cursor-pointer">
            <LanguageIcon  className="h-6 w-6 text-slate-500 hover:text-slate-500 dark:text-slate-400 dark:hover:text-slate-600" aria-hidden="true" />
            </Listbox.Button>
            <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
            <Listbox.Options className="absolute w-42 max-h-72 overflow-auto rounded-md bg-white mt-1 py-1 text-black text-left shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {languages.map((language, index) => (
                <Listbox.Option
                    key={index}
                    value={language}
                    className={({ active }) =>
                `relative cursor-default select-none py-2 pl-3 pr-4 ${
                active ? 'bg-amber-100 text-amber-900' : 'text-slate-900'
                }`}
                >

                {language.name}
                </Listbox.Option>
                ))}
            </Listbox.Options>
            </Transition>
        </Listbox>

        </div>
    </div>

    )
}

export default LanguageSelect;