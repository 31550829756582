import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useLocalStorage } from './useLocalStorage';
import SentimentAnalysis from './SentimentAnalysis';
// import SentenceSimilarity from './SentenceSimilarity';

const Posts = lazy(() => import('./Posts.js'));

  const Dashboard = ({
    lang,
    locale,
    isTouchDevice,
    setIsTouchDevice,
    isOpenModal,
    setIsOpenModal,
    isOpenAnalysisModal,
    setIsOpenAnalysisModal,
    setAnalysis,
    endpoint,
    setEndpoint,
    input,
    refresh,
    setRefresh,
    layout,
    setLayout,
    layoutPref,
    setLayoutPref,
    headlinesQuery,
    searchQuery,
    validCategoryInputs
  }) => {

    const [filterValue, setFilterValue] = useState([]);
    const [source, setSource] = useState(null);
    const [sourceId, setSourceId] = useState(null);
    const [sources, setSources] = useLocalStorage('sources', []);
    const sourcesNoDupes = [...new Set(sources)]; 
    const [flaggedMedia, setFlaggedMedia] = useState([]);
    const [enabled, setEnabled] = useState(Array(sourcesNoDupes.length).fill(false) );
    const [engage, setEngage] = useState(false);
    const [currentHeadline, setCurrentHeadline] = useState(null);
    const [headlineStore, setHeadlineStore] = useLocalStorage('headlines', []);
    const [isEngageOpen, setIsEngageOpen] = useState(false);
    const [engageIcon, setEngageIcon] = useState(null);
    const [sentiment, setSentiment] = useState(false);
    const [isSentimentOpen, setIsSentimentOpen] = useState(false);
    const [similarity, setSimilarity] = useState(null); 
    const [posts, setPosts] = useState([]);
    const [max, setMax] = useState('99');
    const [page, setPage] = useState(1);

    const APIKey = 'ab8b68940d7906e5243512b5ab9bf048';

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);

    function formatDate(date) {
      const formattedDate = date.toISOString();
      return formattedDate.slice(0, -5) + 'Z';
    }
    
    const [queryParams, setQueryParams] = useState({
      lang: lang.code,
      country: locale.code,
      max: max,
      page: page,
      from: formatDate( currentDate ),
      expand: layoutPref,
      apikey: APIKey
    });

    const fetchData = () => {
      const queryString = new URLSearchParams(queryParams).toString();
      const APIUrl = `https://gnews.io/api/v4/${endpoint}${input}&${queryString}`;
    
      fetch(APIUrl)
        .then((response) => response.json())
        .then((data) => {
          setPosts(data);
          setMax('99');
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    
    useEffect(() => {
      if (refresh) {
        fetchData();
        setRefresh(false);
      }
    }, [refresh, endpoint, input, queryParams, layoutPref]);

    useEffect(() => {
      setQueryParams(prevParams => ({
        ...prevParams,
        lang: lang.code,
        country: locale.code,
        max: max,
        page: page,
        expand: layoutPref
      }));
    }, [lang.code, locale.code, max, page, layoutPref]);

    useEffect(() => {
      setRefresh(true);
      if (validCategoryInputs.includes(input)) {
        setEndpoint(headlinesQuery);
        console.log( formatDate(currentDate))
      } else {
        setEndpoint(searchQuery);
      }
    }, [input, validCategoryInputs]);

    useEffect(() => {
      if (currentHeadline !== null ) {   
          let oldHeadlines = JSON.parse(localStorage.getItem('headlines'));
          let oldHeadlinesNoDupes = [...new Set(oldHeadlines)];
          localStorage.setItem('headlines', JSON.stringify([...oldHeadlinesNoDupes, currentHeadline]));
          setHeadlineStore( JSON.parse( localStorage.getItem('headlines') ));
      }
    }, [currentHeadline, setHeadlineStore]);

    useEffect(() => {
      if (source !== null) {
        setSources((prevSources) => {
          const updatedSources = [...prevSources, source ];
          localStorage.setItem("sources", JSON.stringify(updatedSources));

          return updatedSources;
        });
      }
    }, [source, sourceId, setSources]);
   
    useEffect(() => {
      const storedFilterValue = localStorage.getItem('flagged');
      if (storedFilterValue) {
        setFilterValue(JSON.parse(storedFilterValue));
      }
    }, []);

    const handlePagination = (increment) => {
        setPage((prevPage) => prevPage + increment);
        setRefresh(true);
        goToTop();
    };

    const handleEngage = (postIndex) => {
        setEngage(postIndex);
        setIsEngageOpen(!isEngageOpen);
    };

    const handleSentimentMenu = (postIndex) => {
        setSentiment(postIndex);
        setIsSentimentOpen(!isSentimentOpen);
    };

    const goToTop = () => {
      window.scrollTo({
          top: 0,
          behavior: 'smooth',
      });
  };

    const handleFilterChange = (index, value) => {

      const selectedValue = value;
      
      setFilterValue((prevSelectedSources) => {
        if (prevSelectedSources.includes(selectedValue)) {
            return prevSelectedSources.filter((source) => source !== selectedValue);
        } else {
            return [...prevSelectedSources, selectedValue];
        }
      })

      setEnabled((prevSelectedSources) => {
        const updatedSwitchValues = [...prevSelectedSources];
        updatedSwitchValues[index] = !updatedSwitchValues[index];
        return updatedSwitchValues;
      })
  
      const updatedFlaggedSources = enabled[index]
      ? [...flaggedMedia, value]
      : flaggedMedia.filter((source) => source !== value);
      localStorage.setItem('flagged', JSON.stringify(updatedFlaggedSources));
    };

    const handleFilterModal = (value, index) => {
      setFilterValue(value);
      setIsOpenModal(false);
    }

    const handleModal = () => {
      setIsOpenModal(true);
    }

    const toggleLayout = () => {
      setLayout((prevLayout) => (prevLayout === 'default' ? 'content' : 'default'));
      setLayoutPref((prevLayoutPref) => (prevLayoutPref === 'default' ? 'content' : 'default'));
      setQueryParams((prevParams) => ({
        ...prevParams,
        expand: layout === 'default' ? 'content' : 'default',
      }));
    };
  
return(
    
    <div className="dashboard w-full bg-white dark:bg-gray-950">

        <Suspense fallback={<div>Loading...</div>}></Suspense>

        <Posts 
        posts={posts} 
        setPosts={setPosts} 
        page={page} 
        setPage={setPage} 
        handlePagination={handlePagination}        
        engage={engage} 
        isEngageOpen={isEngageOpen} 
        setIsEngageOpen={setIsEngageOpen} 
        handleEngage={handleEngage} 
        engageIcon={engageIcon} 
        setEngageIcon={setEngageIcon}
        sentiment={sentiment} 
        isSentimentOpen={isSentimentOpen} 
        setIsSentimentOpen={setIsSentimentOpen} 
        handleSentimentMenu={handleSentimentMenu} 
        isTouchDevice={isTouchDevice} 
        setIsTouchDevice={setIsTouchDevice} 
        source={source} 
        setSource={setSource} 
        sourceId={sourceId} 
        setSourceId={setSourceId} 
        sources={sources} 
        setSources={setSources}  
        isOpenAnalysisModal={isOpenAnalysisModal} 
        setIsOpenAnalysisModal={setIsOpenAnalysisModal} 
        handleFilterModal={handleFilterModal} 
        filterValue={filterValue} 
        setFilterValue={setFilterValue} 
        handleFilterChange={handleFilterChange}
        layout={layout}
        setLayout={setLayout} 
        toggleLayout={toggleLayout} 
        isOpenModal={isOpenModal} 
        setIsOpenModal={setIsOpenModal}
        handleModal={handleModal} 
        locale={locale} 
        enabled={enabled} 
        setEnabled={setEnabled} 
        flaggedMedia={flaggedMedia} 
        setFlaggedMedia={setFlaggedMedia} 
        setCurrentHeadline={setCurrentHeadline} 
        />

      <SentimentAnalysis 
      headline={currentHeadline}  
      setAnalysis={setAnalysis}  
      />


{/*
      { headlineStore  &&

      <SentenceSimilarity 
      headline={currentHeadline} 
      headlines={localStorage.getItem('headlines')} 
      similarity={similarity} 
      setSimilarity={setSimilarity} 
      />

      } */}

    </div>
    )
};

export default Dashboard;