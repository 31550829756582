
import  {  useRef, useEffect } from 'react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid';

const  MobileSearch = ({handleMobileClick, handleKeyPress, isSearchOpen, setIsSearchOpen }) => {

  const searchRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearchOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isSearchOpen) {
      inputRef.current.focus();
    }
  }, [isSearchOpen]);

  return (

    <div>

      <div className={isSearchOpen ? "lg:hidden transition duration fixed z-[100] inset-0 bg-white dark:bg-slate-950 bg-opacity-25 dark:bg-opacity-80" : "hidden"}>

        <div className="flex w-full h-full container mx-auto place-items-center">

          <div ref={searchRef} className="inline-flex w-4/5 mx-auto max-w-[90%] items-center justify-center py-1 px-2 bg-white rounded-lg">
            
            <button onClick={handleMobileClick}>
              <MagnifyingGlassIcon className="h-6 w-6 mr-2 text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-500 cursor-pointer"></MagnifyingGlassIcon>
            </button>

            <input 
            name="search" 
            className="z-[999] w-full appearance-none outline-none text-2xl text-slate-900" 
            type="text" 
            placeholder="Search" 
            onKeyDown={handleKeyPress} 
            ref={inputRef} 
            /> 

            <button className="z-[999]" onClick={handleMobileClick}>
              <XMarkIcon className="h-7 w-7 text-slate-800 hover:text-slate-900 dark:text-slate-500 dark:hover:text-slate-400" aria-hidden="true"  />
            </button>
            
          </div>

        </div>

      </div>
    </div>
  );
}

export default MobileSearch;