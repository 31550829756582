import { useRef, useEffect } from 'react';
import { XMarkIcon, Bars3Icon } from '@heroicons/react/20/solid';
import countries from './country-list.json';

const TouchDeviceMenu = ({isOpen, setIsOpen, handleCategory, handleCountry, locale}) => {

    const categories = ['general', 'world', 'nation', 'business', 'technology', 'entertainment', 'sports', 'science', 'health'];

    
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (menuRef.current && !menuRef.current.contains(e.target)) {
            setIsOpen(false);
            }
        };

        const handleBlur = () => {
            setIsOpen(false);
            };

        const drawer = document.querySelector('#drawerOverlay');
        drawer.addEventListener('click', handleClickOutside);
        drawer.addEventListener('blur', handleBlur, true);

        return () => {
            drawer.removeEventListener('click', handleClickOutside);
            drawer.removeEventListener('blur', handleBlur, true);
        };
    }, [setIsOpen]);

    return(
        <div className="lg:hidden flex dark:bg-slate-950">
            <button onClick={() => setIsOpen(!isOpen)}>
                <Bars3Icon className="h-6 w-6 text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-500" aria-hidden="true" /></button>
                <div id="drawer"
                ref={menuRef}
                className={isOpen ? "fixed effect-slide slide-in top-0 left-0 z-40 h-screen w-80 p-4 overflow-y-auto bg-white dark:bg-slate-950 border-r-1" : "fixed effect-slide slide-out top-0 -left-80 z-40 h-screen w-80 p-4 overflow-y-auto bg-white dark:bg-slate-950"} 
                tabIndex="-1" 
                aria-labelledby="drawer-label">
                    <button className="text-slate dark:text-white dark:hover:text-gray-600 text-sm p-2 mr-2 fixed top-0 right-0">
                        <XMarkIcon className="h-6 w-6 text-slate-400 hover:text-slate-600" aria-hidden="true" onClick={() => setIsOpen(!isOpen)} /></button>
                    <div className="mt-8 p-3 text-left">
                        <h4 className="text-slate-950 dark:text-white m-0 p-3 font-bold"> Categories</h4>
                        <ul className="m-0 px-2 py-3">
                        { categories.map((category, idx) => (
                            <li className="m-0 px-2 py-3" key={idx}>
                                <button className="px-2 text-slate-800 hover:text-slate-500 capitalize dark:text-white dark:hover:text-slate-300" onClick={handleCategory} value={category}>{category}</button>
                            </li>
                        ))}
                        </ul>
                        <h4 className="text-slate-950 dark:text-white m-0 mt-4 p-3 font-bold"> Countries</h4>
                        <ul className="m-0 px-2 py-3">
                        {countries.map((country, idx) => (
                            <li className="m-0 px-2 py-3" key={idx}>
                            <button className="px-2 text-slate-800 hover:text-slate-500 capitalize dark:text-white dark:hover:text-slate-300" onClick={() => handleCountry(country)} value={country.code}>{country.name}</button>
                        </li>
                        ))}
                        </ul>
                </div>

            </div>
            <div 
            id="drawerOverlay"
            className={isOpen ? "transition duration fixed z-10 inset-0 z-39 bg-slate-400 dark:bg-slate-950 bg-opacity-25 dark:bg-opacity-30" : "collapse"}></div>
        </div>
    );
}

export default TouchDeviceMenu;