const CategoryFilter = ({handleCategory}) => {

    const categories = ['general', 'world', 'nation', 'business', 'technology', 'entertainment', 'sports', 'science', 'health'];

    return(
        <div className="hidden md:flex items-center justify-center py-3 bg-white-200 dark:bg-slate-950 border border-x-0 border-slate-200 dark:border-slate-700">
            <div>
                <div className="nav-filter-category flex items-center text-sm text-black dark:text-white">
                    { categories.map((category, idx) => (
                        <button className="px-2 hover:text-slate-600 dark:text-slate-200 dark:hover:text-slate-400 capitalize" key={idx} onClick={handleCategory} value={category}>{category}</button>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CategoryFilter;