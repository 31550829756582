import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="bg-white dark:bg-slate-950 text-slate-700 dark:text-slate-200" id="error-page">
      <div className="container mx-auto flex justify-center items-center w-full h-screen max-w-sm">
        <div>
          <h1 className="text-2xl">Sorry buddy, an error has occurred...</h1>
          <p className="text-4xl">{error.status} {error.statusText}</p> 
        </div>
      </div>
    </div>
  );
}