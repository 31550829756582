import CategoryFilter from './CategoryFilter';
import SearchFilter from './SearchFilter';
import CountrySelect from './CountrySelect';
import TouchDeviceMenu from './TouchDeviceMenu';
import MobileSearch from './MobileSearch';
import LanguageSelect from './LanguageSelect';
import EngagementIndicator from './EngagementIndicator';
import { MagnifyingGlassIcon, BoltIcon } from '@heroicons/react/20/solid';

const Header = ({
    locale,
    lang,
    useLocalStorage,
    handleCategory,
    handleChange, 
    handleSearch,  
    handleClick, 
    handleMobileClick,
    handleLanguage, 
    handleKeyPress,
    searchItems, 
    country, 
    setCountry, 
    setEndpoint, 
    setRefresh, 
    headlinesQuery, 
    isOpen, 
    setIsOpen, 
    isSearchOpen,
    setIsSearchOpen,
    language, 
    setLanguage,
    engageIcon,
    analysis,
    setAnalysis,
    setIsOpenAnalysisModal,
    setSource,
    source,
    sources,
    toggle,
    setToggle,
    handleCountry,
    isTouchDevice
    }) => {

    return (

    <header className="container mx-auto transition-all bg-white dark:bg-gray-950">

        <div className="p-4 lg:p-4 grid grid-cols-3 justify-items-center lg:justify-evenly items-center">

            <div className="lg:w-auto flex justify-self-start align-self-center">
                
                <TouchDeviceMenu
                isOpen={isOpen} 
                setIsOpen={setIsOpen} 
                handleCategory={handleCategory} 
                handleCountry={handleCountry} 
                locale={locale} 
                />

                <CountrySelect 
                handleChange={handleChange} 
                locale={locale} 
                setCountry={setCountry} 
                country={country} 
                setEndpoint={setEndpoint} 
                setRefresh={setRefresh} 
                headlinesQuery={headlinesQuery} 
                useLocalStorage={useLocalStorage} 
                />

                <LanguageSelect 
                handleLanguage={handleLanguage} 
                language={language} 
                lang={lang} 
                />
                
                
            </div>

            <div className="lg:w-auto">
                <h1 className="flex w-full logotype text-2xl md:text-4xl text-black dark:text-white">Nama<BoltIcon className="h-5 w-5 md:h-7 md:w-7 -ml-1 md:mt-0 leading-none text-amber-300 hover:text-amber-400" aria-hidden="true" />té</h1>
                <div className={ isTouchDevice ?  "fade-in text-slate-800 dark:text-slate-200 text-[9px] px-2" : "fade-in text-slate-800 dark:text-slate-200 text-xs px-2"}>...there buddy</div>
            </div>

            <div className="flex h-full lg:w-auto lg:place-self-end lg:align-self-center justify-self-end align-self-center">

                <div className="flex items-center">

                    <EngagementIndicator 
                    engageIcon={engageIcon} 
                    setAnalysis={setAnalysis}
                    analysis={analysis} 
                    setIsOpenAnalysisModal={setIsOpenAnalysisModal}
                    />

                    <SearchFilter 
                    searchItems={searchItems} 
                    handleClick={handleClick} 
                    handleSearch={handleSearch} 
                    handleKeyPress={handleKeyPress}
                    toggle={toggle} 
                    setToggle={setToggle} 
                    />

                    <button className="flex lg:hidden ml-3" onClick={handleMobileClick}>
                        <MagnifyingGlassIcon className="h-7 w-7 text-slate-500 cursor-pointer" aria-hidden="true"></MagnifyingGlassIcon>
                    </button>

                    <MobileSearch 
                    handleSearch={handleSearch} 
                    handleMobileClick={handleMobileClick} 
                    handleKeyPress={handleKeyPress} 
                    isSearchOpen={isSearchOpen}  
                    setIsSearchOpen={setIsSearchOpen} 
                    />

                </div>
            </div>
        </div>

        <CategoryFilter 
        handleCategory={handleCategory} 
        handleLanguage={handleLanguage} 
        setLanguage={setLanguage} 
        language={language} 
        lang={lang} 
        setEndpoint={setEndpoint} 
        setRefresh={setRefresh} 
        headlinesQuery={headlinesQuery} 
        useLocalStorage={useLocalStorage}
        source={source}
        setSource={setSource}
        sources={sources} 
        />
        </header>
    );
}

export default Header;