import React, {Fragment} from 'react';
import { Listbox, Transition } from '@headlessui/react';
import countries from './country-list.json';

const CountrySelect = ({handleChange, locale}) => {

    return(
        <div className="hidden lg:flex z-[11] ml-3 mr-3 md:ml-0">
            <div className="relative self-center">

                <Listbox value={locale} onChange={handleChange}>
                    <Listbox.Button 
                    className="relative flex w-full cursor-default py-1 pl-4 lg:pl-0 focus:outline-none sm:text-sm">
                   {locale.code && (<span className={`fi-${locale.code} fi inline-flex self-center`}></span> )}
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                    <Listbox.Options className="absolute z-40 w-42 max-h-72 overflow-auto rounded-md bg-white mt-1 py-1 text-black text-left shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {countries.map((country, index) => (
                        <Listbox.Option
                            key={index}
                            value={country}
                            className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-3 pr-4 ${
                        active ? 'bg-amber-100 text-amber-900' : 'text-slate-900'
                        }`}
                        >

                        {country.name}
                        </Listbox.Option>
                        ))}
                    </Listbox.Options>
                    </Transition>
                    </Listbox>

            </div>
        </div>
    );
}

export default CountrySelect;