import { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { BoltIcon } from '@heroicons/react/20/solid';

const AnalysisModal = ({
  isOpenAnalysisModal, 
  setIsOpenAnalysisModal, 
  analysis
}) => {

  const headlines = localStorage.getItem('headlines');
  const isHeadlinesEmpty = headlines && Array.isArray(JSON.parse(headlines)) && JSON.parse(headlines).length === 0;

  const score = analysis['score'];
  const label = analysis['label'];
  const emotion = label === 'POSITIVE' ? 'good' : 'bad';

  const Result = () => {
    return(
      <div>
        <p className="text-sm text-gray-500">
          Your sentiment analysis score is <strong>{score}% {label}</strong>. 
        </p>
      </div>
    )}

    return(
        <>  
        <Transition appear show={isOpenAnalysisModal} as={Fragment}>
          <Dialog as="div" className="relative z-[12]" open={isOpenAnalysisModal} onClose={() => setIsOpenAnalysisModal(false)}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>
  
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel 
                  className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                  >
                    <Dialog.Title
                      as="h2"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >

                      { isHeadlinesEmpty ? (<div className="text-lg">No results yet</div>) :  (<span className="text-2xl">✨Namasté ✨</span>) }

                    </Dialog.Title>
                    <div className="mt-2">

                      { isHeadlinesEmpty ? (<p className="mb-6 text-sm text-gray-500">Get charged up  <BoltIcon className="inline-flex h-6 w-6 text-amber-300 hover:text-amber-400" aria-hidden="true" /> about an article or two, and we can analyze the results, together... </p>) : (<Result />) }

                    </div>
  
                    <div className="mt-4 flex justify-between">

                      <button
                        type="button"
                        className="w-full mr-2 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={() => setIsOpenAnalysisModal(false)}
                      >
                        { isHeadlinesEmpty  ? ('Cool, Cool...') : ('Got it, thanks !') }

                      </button>

                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>

      </>
    );
}

export default AnalysisModal
