  
export default function Footer(){
    return(
        <footer>
            <div className="bg-slate-200 dark:bg-slate-950 border-t dark:border-slate-700 border-slate-200">
            <div className="mx-auto text-slate-800 dark:text-slate-400 py-6">
                <div className="text-sm text-center">Use of this site is for educational purposes only. All content copyright &copy; the respective owners and/or organizations. </div>
            </div>
            </div>
      </footer>
    )
}