import { BoltIcon } from '@heroicons/react/20/solid';

const EngagementIndicator = ({engageIcon, analysis, setIsOpenAnalysisModal}) => {

    return (

        <div className="relative inline-flex align-center">
            <button onClick={() => setIsOpenAnalysisModal(true) } className="p-1 cursor-pointer focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
                <BoltIcon className={ engageIcon ? "animate-pulse h-6 w-6 text-amber-300 hover:text-amber-400"  : "h-6 w-6 text-amber-300 hover:text-amber-400"} aria-hidden="true"  />
            </button>
        </div>
        

    );
}

export default EngagementIndicator;