import { useEffect } from 'react';

const queryAPI = async (data) => {
  try {
    const API_TOKEN = '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';

    const response = await fetch(
      'https://api-inference.huggingface.co/models/distilbert-base-uncased-finetuned-sst-2-english',
      {
        headers: { Authorization: `Bearer ${API_TOKEN}` },
        method: 'POST',
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      throw new Error('API request failed');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error.message);
    return null;
  }
};

const SentimentAnalysis = (props) => {

  const { headline, setAnalysis } = props;

  useEffect(() => {
    async function fetchData() {
        const data = { inputs: JSON.stringify(headline) };
        const result = await queryAPI(data);
        const obj = Object.entries(result[0]);

        function fixed(x) {
          return x.toFixed(2);
        }

        const label = obj[0][1]['label'];
        const score = fixed( (obj[0][1]['score'] *100) );
        setAnalysis({"label": label , "score" : score} );

    }

    fetchData();
  }, [headline, setAnalysis]);

   return null;

};

export default SentimentAnalysis;